import React from "react";

interface DialogProps {
  dialogNode: string | null;
  newNodeName: string;
  setNewNodeName: (name: string) => void;
  handleRenameNode: () => void;
  handleDeleteNode: () => void;
  closeDialog: () => void;
}

const Dialog: React.FC<DialogProps> = ({
  dialogNode,
  newNodeName,
  setNewNodeName,
  handleRenameNode,
  handleDeleteNode,
  closeDialog,
}) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <h3>Node: {dialogNode}</h3>
        <input
          type="text"
          value={newNodeName}
          onChange={(e) => setNewNodeName(e.target.value)}
          style={{ marginBottom: "10px", width: "100%" }}
        />
        <button onClick={handleRenameNode} style={{ marginRight: "10px" }}>
          Rename
        </button>
        <button onClick={handleDeleteNode} style={{ marginRight: "10px" }}>
          Delete
        </button>
        <button onClick={closeDialog}>Cancel</button>
      </div>
    </div>
  );
};

export default Dialog;
